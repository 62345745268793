import request from '@/utils/request'


// 查询动态评论列表
export function listDynamic(query) {
  return request({
    url: '/biz/dynamicComment/list',
    method: 'get',
    params: query
  })
}

// 查询动态评论分页
export function pageDynamic(query) {
  return request({
    url: '/biz/dynamicComment/page',
    method: 'get',
    params: query
  })
}

// 查询动态评论详细
export function getDynamic(data) {
  return request({
    url: '/biz/dynamicComment/detail',
    method: 'get',
    params: data
  })
}

// 新增动态评论
export function addDynamic(data) {
  return request({
    url: '/biz/dynamicComment/add',
    method: 'post',
    data: data
  })
}

// 修改动态评论
export function updateDynamic(data) {
  return request({
    url: '/biz/dynamicComment/edit',
    method: 'post',
    data: data
  })
}

// 删除动态评论
export function delDynamic(data) {
  return request({
    url: '/biz/dynamicComment/delete',
    method: 'post',
    data: data
  })
}
